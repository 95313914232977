import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "../../Modal/Modal"; // Adjust import path if necessary
import { getFileFromKey } from "../../../helpers/apis/user";
import { useSelector } from "react-redux";

function ViewData({ close, data }) {
  const [imageUrl, setImageUrl] = useState(null); // State to hold the image URL
  const auth = useSelector((state) => state.auth);

  // Function to fetch the image URL
  const fetchFile = async (imageString) => {
    if (imageString && !imageString.startsWith("https://res.cloudinary.com/")) {
      try {
        const result = await getFileFromKey(imageString, auth);
        setImageUrl(result.data.url); // Set the URL in state
      } catch (error) {
        console.error("Error fetching file:", error.message);
        setImageUrl(null); // Set to null or a fallback image URL in case of error
      }
    } else {
      setImageUrl(imageString); // Set the Cloudinary URL or other valid URL
    }
  };

  // Trigger fetching the file when the component mounts or when the data.profile changes
  useEffect(() => {
    if (data?.profile) {
      fetchFile(data.profile);
    }
  }, [data?.profile]); // Dependency on data.profile to refetch when it changes

  return (
    <Modal open={true} closeModal={close} title="Club Details">
      <div className="flex flex-col sm:flex-row items-center gap-4">
        <div className="flex-1">
          <button
            type="button"
            className={`${data?.status === "blocked"
                ? "bg-red-600"
                : data?.status === "rejected"
                  ? "bg-black"
                  : data?.status === "awaiting"
                    ? "bg-yellow-500"
                    : "bg-green-600"
              } rounded-2xl text-white px-3 py-1 text-sm`}
          >
            {data?.status}
          </button>
          <div className="my-2">
            <span className="text-gray-500 font-medium">Name: </span>
            <span className="font-semibold">{data?.name}</span>
          </div>
          <div className="mb-2">
            <span className="text-gray-500 font-medium">Email: </span>
            <span className="font-semibold">{data?.email}</span>
          </div>
          <div className="mb-2">
            <span className="text-gray-500 font-medium">Phone: </span>
            <span className="font-semibold">{data?.phone}</span>
          </div>
          <div className="mb-4">
            <span className="text-gray-500 font-medium">Registered On: </span>
            <span className="font-semibold">
              {data?.createdAt &&
                new Date(data.createdAt).toLocaleDateString("en-US")}
            </span>
          </div>
        </div>
        {/* Conditionally render the image if the URL is resolved */}
        {imageUrl && (
          <img
            src={imageUrl}
            alt="profile"
            className="w-32 h-36 border-2 border-black rounded-lg"
          />
        )}
      </div>
      <div className="mt-6">
        <div className="mb-2">
          <span className="text-gray-500 font-medium">Description: </span>
          <span className="font-semibold break-words">{data?.description || "-"}</span>
        </div>
        <div>
          <span className="text-gray-500 font-medium">Comment: </span>
          <span className="font-semibold">{data?.comment || "-"}</span>
        </div>
      </div>
    </Modal>
  );
}

ViewData.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.shape({
    status: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    profile: PropTypes.string,
    description: PropTypes.string,
    comment: PropTypes.string,
  }).isRequired,
};

export default ViewData;
