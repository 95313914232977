import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import arrowIcon from "../../assets/icons/arrow.svg";
import TournamentDashboardCard from "../../components/Cards/TournamentDashboardCard";
import { getTournaments } from "../../helpers/apis/guest";

function UpcomingSection() {
  const [cards, setCards] = useState([]);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    getTournaments("filter=upcoming", auth)
      .then((res) => {
        if (res.data.success) {
          const allTournaments = res.data.data.tournaments;
          setCards(allTournaments.slice(-3));
        } else {
          setCards([]);
        }
      })
      .catch(() => setCards([]));
  }, [auth]);


  return (
    <div className="bg-gray-100">
      <div className="flex flex-col p-4 sm:p-6 lg:p-10 max-w-[1500px] mx-auto box-border">
        <div className="flex justify-between items-center mb-6">
          <div className="text-xl sm:text-2xl font-bold">Upcoming Tournaments</div>
          <Link to="/explore" className="flex min-w-20 justify-end items-center gap-1 sm:gap-2 cursor-pointer">
            <span className="text-sm sm:text-base">View All</span>
            <img src={arrowIcon} className="w-4 sm:w-5" alt="explore" />
          </Link>
        </div>

        {/* Conditional rendering */}
        {cards.length === 0 ? (
          <div className="w-full flex justify-center items-center p-4">
            <p className="text-xl font-semibold text-gray-500">No upcoming matches</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {cards.map((ele) => (
              <div key={ele._id} className="flex-grow">
                <TournamentDashboardCard data={ele} />
              </div>
            ))}
          </div>
        )}
      </div>

    </div>
  );
}

export default UpcomingSection;
