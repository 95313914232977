import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdOutlineLocationOn, MdCalendarMonth } from "react-icons/md";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { getFileFromKey } from "../../helpers/apis/user";
import { IoIosFootball } from "react-icons/io";

function TournamentDashboardCard({
  data,
  showAvatar,
  showBookMark,
  showEditButton,
}) {
  const location = useLocation();
  const [isSaved, setIsSaved] = useState(data?.isSaved || false);
  const [coverImageUrl, setCoverImageUrl] = useState(data?.cover || "");
  const [teamImages, setTeamImages] = useState([]);
  const auth = useSelector((state) => state.auth);
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const addToWatch = () => {
    if (!auth.accessToken)
      navigate("/login", { state: { from: location.pathname } });
    axios
      .get(`/user/tournament/${data._id}/save`)
      .then((res) => {
        if (res.data.success) {
          setIsSaved(true);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    const fetchImages = async () => {
      if (
        data?.cover &&
        !data.cover.startsWith("https://res.cloudinary.com/")
      ) {
        try {
          const result = await getFileFromKey(data.cover, auth);
          setCoverImageUrl(result.data.success ? result.data.url : data.cover);
        } catch (error) {
          console.error("Error fetching cover image:", error);
        }
      } else if (data?.cover) {
        // If it's a Cloudinary URL, set it directly
        setCoverImageUrl(data.cover);
      }

      const fetchedTeamImages = await Promise.all(
        data?.teams.map(async (team) => {
          try {
            const result = await getFileFromKey(team.profile, auth);
            return result.data.success ? result.data.url : team.profile;
          } catch (error) {
            console.error("Error fetching team profile image:", error);
            return team.profile;
          }
        })
      );

      setTeamImages(fetchedTeamImages);
    };

    fetchImages();
  }, [data]);

  return (
    <Link
      to={`/tournament/${data?._id}`}
      state={{ from: location.pathname }}
      className="block w-full h-full"
    >
      <div className="bg-white border p-4 flex flex-col h-full shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">
        {/* Tournament Title and Date */}
        <div className="flex gap-4 items-center">
          <img
            src={coverImageUrl}
            alt="Tournament Cover"
            className="w-16 h-16 rounded-lg"
          />
          <div>
            <div className="text-xl font-semibold">{data?.title}</div>
            <div className="flex items-center text-gray-500 text-sm gap-1">
              <MdCalendarMonth />
              <span className="whitespace-nowrap">
                {new Date(data?.start_date).toLocaleDateString("en-GB")}
              </span>
            </div>
          </div>
        </div>

        {/* Location */}
        <div className="flex items-center gap-1 mt-2">
          <IoIosFootball className="flex-shrink-0" />
          <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[250px] sm:max-w-[300px]">
            {(() => {
              switch (data?.tournament_type) {
                case "t1":
                  return "League";
                case "t2":
                  return "Knockout";
                case "t3":
                  return "Group Stage + Knockout";
                default:
                  return "Unknown Type";
              }
            })()}
          </div>
        </div>
        <div className="flex items-center gap-1 mt-1">
          <MdOutlineLocationOn className="flex-shrink-0" />
          <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[250px] sm:max-w-[300px]">
            {data?.location || "Location not available"}
          </div>
        </div>

        {/* Divider */}
        <hr className="my-2" />

        {/* Host and Teams */}
        <div className="flex justify-between mt-auto">
          <div className="font-medium">
            <span className="text-gray-500 text-sm font-normal">Host:</span>{" "}
            {data.host.name}
          </div>

          {showAvatar && (
            <div className="flex items-center">
              {teamImages.slice(0, 3).map((url, index) => (
                <span
                  key={data.teams[index]?._id || index} // Unique fallback key
                  title={data.teams[index]?.name}
                  className="relative border border-white rounded-full overflow-hidden w-[30px] h-[30px] hover:border-blue-500"
                >
                  <img
                    className="h-full w-full object-cover"
                    src={url}
                    alt="Team Profile"
                  />
                </span>
              ))}
              {data?.teams?.length > 3 && (
                <span className="relative border flex justify-center items-center text-xs bg-white rounded-full w-[30px]">
                  +{data.teams_count - teamImages.length}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

TournamentDashboardCard.defaultProps = {
  showAvatar: true,
  showBookMark: true,
  showEditButton: false,
};

TournamentDashboardCard.propTypes = {
  data: PropTypes.object.isRequired,
  showAvatar: PropTypes.bool,
  showBookMark: PropTypes.bool,
  showEditButton: PropTypes.bool,
};

export default TournamentDashboardCard;
