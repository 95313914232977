/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import spinnerIcon from "../../assets/icons/spinner.svg";
import axios from "../../config/api";
import { useErrorToast } from "../../hooks/useToast";
import Modal from "../Modal/Modal";
import CenterPopup from "../Popup/CenterPopUp";
import { useSelector } from "react-redux";
import { getFileFromKey } from "../../helpers/apis/user";

const fetchImage = async (imageKey, auth) => {
  try {
    const result = await getFileFromKey(imageKey, auth);
    return result.data.success ? result.data.url : imageKey;
  } catch {
    return imageKey; // Fallback to the original key
  }
};

function TeamData({ close, data, openState }) {
  const [fetching, setFetching] = useState(false);
  const [clubData, setClubData] = useState({});
  const [playerImages, setPlayerImages] = useState({});
  const [showDoc, setShowDoc] = useState({ show: false, doc: "" });
  const auth = useSelector((state) => state.auth);
  const [coverImageUrl, setCoverImageUrl] = useState("");

  useEffect(() => {
    const fetchCoverImage = async () => {
      if (clubData.profile) {
        const coverImage = await fetchImage(clubData.profile, auth);
        setCoverImageUrl(coverImage);
      }
    };
    fetchCoverImage();
  }, [clubData.profile, auth]);

  useEffect(() => {
    const fetchPlayerImages = async () => {
      if (clubData?.players?.length) {
        const images = await Promise.all(
          clubData.players.map(async (player) => ({
            id: player._id,
            url: await fetchImage(player.profile, auth),
          }))
        );

        const imagesMap = images.reduce((acc, curr) => {
          acc[curr.id] = curr.url;
          return acc;
        }, {});
        setPlayerImages(imagesMap);
      }
    };
    fetchPlayerImages();
  }, [clubData.players, auth]);

  const fetchTeamData = () => {
    axios
      .get(`/tournament/${data.tournament}/club/${data.teamId}`)
      .then((res) => {
        if (res?.data?.success) {
          setClubData(res.data.data);
        } else {
          useErrorToast({ message: "Can't fetch organization data" });
          close();
        }
      })
      .catch(() => {
        useErrorToast({ message: "Can't fetch organization data" });
        close();
      })
      .finally(() => {
        setFetching(false);
      });
  };

  useEffect(() => {
    if (openState) {
      setFetching(true);
      fetchTeamData();
    }
  }, [openState]);

  return (
    <CenterPopup
      title="Team Details"
      openState={openState}
      close={close}
    >
      <div>
        {fetching ? (
          <div className="h-[50vh] flex items-center justify-center">
            <img
              src={spinnerIcon}
              className="w-7 animate-spin"
              alt="Loading..."
            />
          </div>
        ) : (
          <div>
            <div>
              <div className="flex justify-between items-end">
                <div className="rounded-full z-20 w-fit bg-white border-gray-400 border p-1">
                  <img
                    src={coverImageUrl}
                    alt="profile"
                    className="w-20 h-20 rounded-full"
                  />
                </div>
              </div>
              <hr className="-mt-2" />
            </div>
            <h1 className="font-medium mt-3 text-xl">{clubData.name}</h1>
            <p className="my-2 text-slate-500">Players</p>

            <div className="grid grid-cols-2 min-[400px]:grid-cols-4 auto-rows-auto gap-1 sm:gap-2">
              {clubData?.players?.map((ele) => (
                <div
                  key={ele._id}
                  onClick={() => {
                    if (data.isHost) setShowDoc({ show: true, doc: ele.doc });
                  }}
                  className="w-full h-40 px-2 rounded border-black shadow border relative bg-center bg-cover bg-no-repeat flex items-end after:content-[''] after:bg-gradient-to-t after:from-black after:via-transparent after:to-transparent after:absolute after:top-0 after:bottom-0 after:right-0 after:left-0"
                  style={{
                    backgroundImage: `url('${playerImages[ele._id] || ele.profile}')`,
                  }}
                >
                  <div className="z-10 text-white mb-2">
                    <h2 className="text-base font-bold leading-4">
                      {ele.name}
                    </h2>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {showDoc.show && (
        <Modal
          closeOnOutSide
          closeModal={() => setShowDoc({ show: false, doc: "" })}
        >
          <div className="lg:w-[600px] w-[80vw]" />
          <div className="h-[70vh]">
            <iframe
              src={showDoc.doc}
              title="doc"
              scrolling="auto"
              height="87%"
              width="100%"
              frameBorder="0"
              className="absolute top-16 left-0 bottom-0"
            />
          </div>
        </Modal>
      )}
    </CenterPopup>
  );
}

TeamData.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  openState: PropTypes.bool.isRequired,
};

export default TeamData;
