import axios from "../../config/api";

export const getUserTournaments = async (query, auth) => {
  const result = await axios.get(`user/tournaments?${query}`, {
    headers: {
      Authorization: `Bearer ${auth?.accessToken}`,
    },
  });
  return result;
};

export const getUserTournament = async (id, auth) => {
  const result = await axios.get(`user/tournament/${id}`, {
    headers: {
      Authorization: `Bearer ${auth?.accessToken}`,
    },
  });
  return result;
};

export const getFileFromKey = async (fileKey) => {
  const result =await axios.get(`user/file/view`, {
    params: { fileKey },
  });
  return result;
};

export const getFileFromKeyAsURL = async (fileKey, auth) => {

  try {
    const response = await axios.get(`user/file/view`, {
      params: { fileKey },
    });
    return response.data.url;
  } catch (error) {
    console.error('Error fetching file URL:', error);
    throw error; 
  }
};
