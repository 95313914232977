import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMatches } from "../../helpers/apis/guest";
import { getFileFromKey } from "../../helpers/apis/user";

function LiveMatch() {
  const [cards, setCards] = useState([]);
  const [teamProfiles, setTeamProfiles] = useState({});
  const auth = useSelector((state) => state.auth);

  const defaultImage = "https://www.gstatic.com/onebox/sports/logos/crest_48dp.png";

  useEffect(() => {
    const fetchImages = async (key) => {
      try {
        const result = await getFileFromKey(key, auth);
        return result.data.success ? result.data.url : defaultImage;
      } catch (error) {
        console.error("Error fetching image:", error);
        return defaultImage; // Fallback image
      }
    };

    const fetchTeamProfiles = async () => {
      const newProfiles = {};

      await Promise.all(
        cards.map(async (matchData) => {
          const { teamA, teamB } = matchData.match;

          if (teamA.profile && !newProfiles[teamA.profile]) {
            newProfiles[teamA.profile] = await fetchImages(teamA.profile);
          }

          if (teamB.profile && !newProfiles[teamB.profile]) {
            newProfiles[teamB.profile] = await fetchImages(teamB.profile);
          }
        })
      );

      setTeamProfiles(newProfiles);
    };

    if (cards.length > 0) {
      fetchTeamProfiles();
    }
  }, [cards, auth]);

  useEffect(() => {
    getMatches()
      .then((res) => {
        if (res.data.success) {
          const allMatches = res?.data?.data;
          setCards(allMatches);
        } else {
          setCards([]);
        }
      })
      .catch(() => {
        setCards([]);
      });
  }, [auth]);
  return (
    <div className="relative box-border">
      <div className="bg-white/60">
        <div className="flex flex-col p-4 sm:p-10 mx-auto">
          <h3 className="text-lg sm:text-xl font-bold mb-6">Upcoming Live Matches</h3>
          <div className="overflow-x-auto scrollbar-hide">
            {cards.length === 0 ? (
              <div className="flex justify-center items-center w-full p-4">
                <p className="text-xl font-semibold text-gray-500">No live matches now</p>
              </div>
            ) : (
              <div className="flex flex-row gap-4 pb-4" style={{ width: "max-content" }}>
                {cards.map((matchData) => (
                  <div
                    key={matchData._id}
                    className="flex-shrink-0 w-72 sm:w-80 flex flex-col items-center gap-4 bg-white border shadow-md rounded-lg py-4 px-2 hover:shadow-2xl transition-transform duration-200 transform hover:-translate-y-1"
                  >
                    <div className="flex justify-between items-start flex-grow w-full">
                      <div className="flex flex-col gap-2 items-center w-52 self-center">
                        <img
                          src={teamProfiles[matchData.match.teamA.profile] || defaultImage}
                          className="w-12 h-12 rounded-full"
                          alt={matchData.match.teamA.name}
                        />
                        <h1 className="text-center font-medium text-sm sm:text-base">
                          {matchData.match.teamA.name}
                        </h1>
                      </div>
                      <div className="flex flex-col items-center gap-4 mt-2 w-24 self-center">
                        <h1 className="text-2xl font-medium">vs</h1>
                        {/* <div className="flex flex-col items-center">
                          <h1 className="font-semibold text-xs sm:text-sm">
                            {matchData.city}
                          </h1>
                        </div> */}
                      </div>
                      <div className="flex flex-col gap-2 items-center w-52 self-center">
                        <img
                          src={teamProfiles[matchData.match.teamB.profile] || defaultImage}
                          className="w-12 h-12 rounded-full"
                          alt={matchData.match.teamB.name}
                        />
                        <h1 className="text-center font-medium text-sm sm:text-base">
                          {matchData.match.teamB.name}
                        </h1>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveMatch;
