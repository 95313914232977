import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import follwersIcon from "../../assets/icons/followers.svg";
import smsIcon from "../../assets/icons/sms.svg";
import callIcon from "../../assets/icons/call.svg";
import PlayerForm from "./PlayerForm";
import ClubForm from "./ClubForm";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { setClubData } from "../../app/slices/clubSlice";
import editIcon from "../../assets/icons/edit.svg";
import spinnerIcon from "../../assets/icons/spinner.svg";
import "./Club.scss";
import TournamentsTab from "./TournamentTab";
import PlayersTab from "./PlayersTab";
import RegisteredTab from "./RegisteredTab";
import { getFileFromKey } from "../../helpers/apis/user";

function PrivateClub() {
  const [addPlayerModal, setAddPlayerModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [data, setData] = useState({});
  const [players, setPlayers] = useState([]);
  const club = useSelector((state) => state.club);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();
  const location = useLocation();
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [numLines, setNumLines] = useState(0);
  const paragraphRef = useRef(null);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (paragraphRef.current) {
      const height = paragraphRef.current.scrollHeight;
      const lineHeight = parseInt(
        window.getComputedStyle(paragraphRef.current).lineHeight,
        10
      );
      const lines = Math.round(height / lineHeight);
      setNumLines(lines);
    }
  }, [data]);

  function handleShowMore() {
    setShowMore(!showMore);
  }

  const fetchData = () => {
    axios
      .get("/user/club")
      .then((res) => {
        if (res.data.success) {
          setData(res?.data?.data);
          setPlayers(res?.data?.data?.players);
          dispatch(setClubData(res?.data?.data));
        } else {
          navigate(location.state?.from || "/user");
        }
      })
      .catch((err) => {
        navigate(location.state?.from || "/user");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (club.name) {
      setData(club);
      setPlayers(club.players);
      setLoading(false);
    } else fetchData();

  }, []);

  const [activeTab, setActiveTab] = useState("players");

  const renderContent = () => {
    switch (activeTab) {
      case "players":
        return (
          <PlayersTab
            auth={auth}
            players={players}
            onAddPlayer={() => setAddPlayerModal(true)}
          />
        );
      case "tournaments":
        return <TournamentsTab />;
      case "registered":
        return <RegisteredTab />;
      default:
        return (
          <PlayersTab
            players={players}
            onAddPlayer={() => setAddPlayerModal(true)}
          />
        );
    }
  };
  const [coverImageUrl, setCoverImageUrl] = useState(data.profile || "");
  useEffect(() => {
    const fetchImages = async () => {
      if (data.profile) {
        try {
          const result = await getFileFromKey(data.profile, auth);
          setCoverImageUrl(result.data.success ? result.data.url : data.profile);
        } catch (error) {
          console.error("Error fetching cover image:", error);
        }
      }
    };

    fetchImages();
  }, [data , auth]);

  return (
    <div className="py-4 bg-[#F8F9FA]">
      {loading && (
        <div className="fixed inset-0 bg-white bg-opacity-75 flex justify-center items-center z-50">
          <img src={spinnerIcon} alt="Loading..." className="w-16 h-16 animate-spin" />
        </div>
      )}
      <div className={`bg-white border shadow-md rounded-lg p-6 mx-4 sm:mx-12 ${loading ? 'opacity-50' : ''}`}>
        <div className="flex flex-col sm:flex-row sm:justify-between items-center">
          <div className="rounded-full w-fit bg-white border-gray-400 border p-2">
            <img
              src={coverImageUrl}
              alt="profile"
              className="w-20 h-20 sm:w-28 sm:h-28 rounded-full"
            />
          </div>
          <div className="flex flex-col gap-2 w-full sm:w-[85%]">
            <div className="flex justify-between">
              <p className="text-lg sm:text-xl font-bold py-0 my-0">{data.name}</p>
              {((auth.role === 'user') || (auth?.access?.some(item => item.title === 'staffManagement' && item.enabled && (item.type === 'Write Only' || item.type === 'Read & Write')))) && (<button
                type="button"
                className="text-sm sm:text-base flex items-center"
                onClick={() => {
                  setEditModal(true);
                }}
              >
                <img className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-2" src={editIcon} alt="edit" />
                <span>Edit</span>
              </button>)}


            </div>
            <div className=" text-gray-600">
              <div className="relative flex flex-col justify-start">
                <p
                  className={`${showMore
                    ? "club-description-show-more text-xs sm:text-sm"
                    : "club-description-show-less text-xs sm:text-sm"
                    }`}
                  ref={paragraphRef}
                >
                  {data.description}
                </p>
                {numLines > 3 && (
                  <button
                    type="button"
                    onClick={handleShowMore}
                    className={`text-blue-800 text-xs sm:text-xs bg-white/80 font-bold p-1 ${!showMore
                      ? "absolute -bottom-1 right-1"
                      : "relative ml-auto mt-0 py-0 mr-1"
                      }`}
                  >
                    {showMore ? "Hide" : "More"}
                  </button>
                )}
              </div>
            </div>
            <div className="mt-3 flex flex-col md:flex-row gap-y-2 gap-x-4 text-center text-sm sm:text-base flex-wrap">
              {/* <Link
                to={`/club/${data._id}`}
                state={{ from: location.pathname }}
                className="flex flex-nowrap gap-2 hover:text-primary items-center"
              >
                <img src={follwersIcon} alt="followers" className="w-3 sm:w-4" />
                <p className="text-gray-600 text-xs sm:text-sm">{data.followers} followers</p>
              </Link> */}
              <a
                href={`mailto:${data.email}`}
                className="flex flex-nowrap gap-2 text-gray-600 items-center"
              >
                <img src={smsIcon} alt="followers" className="w-3 sm:w-4" />
                <p className="text-ellipsis overflow-hidden text-xs sm:text-sm">{data.email}</p>
              </a>
              <a
                href={`tel:${data.phone}`}
                className="flex flex-nowrap gap-2 text-gray-600 items-center"
              >
                <img src={callIcon} alt="followers" className="w-3 sm:w-4" />
                <p className="text-xs sm:text-sm">{data.phone}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1400px] mx-auto px-5 sm:px-10 ">
        <div className="flex flex-col mt-8">
          <div className="flex gap-1 overflow-auto ml-auto mr-0">
            <button
              onClick={() => setActiveTab("players")}
              className={`px-2 py-1 text-xs sm:text-base sm:px-3 sm:py-1.5 ${activeTab === "players" ? "text-primary" : "text-gray-500"
                }`}
            >
              Players
            </button>
            <button
              onClick={() => setActiveTab("tournaments")}
              className={`px-2 py-1 text-xs sm:text-base sm:px-3 sm:py-1.5 ${activeTab === "tournaments" ? "text-primary" : "text-gray-500"
                }`}
            >
              Tournaments
            </button>
            <button
              onClick={() => setActiveTab("registered")}
              className={`px-2 py-1 text-xs sm:text-base sm:px-3 sm:py-1.5 ${activeTab === "registered" ? "text-primary" : "text-gray-500"
                }`}
            >
              Registered
            </button>
          </div>
          <hr />
          <div className="mt-4">{renderContent()}</div>
        </div>
        <PlayerForm
          openState={addPlayerModal}
          onClose={() => {
            setAddPlayerModal(false);
          }}
          reRender={fetchData}
        />
        <ClubForm
          isEdit
          openState={editModal}
          onClose={() => {
            setEditModal(false);
          }}
          data={{
            name: data.name,
            email: data.email,
            phone: data.phone,
            description: data.description,
          }}
          profile={data.profile}
          reRender={fetchData}
        />
      </div>
    </div>
  );
}

export default PrivateClub;
