import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import MatchData from "./LiveTournament/MatchData";
import { MdOutlineLocationOn } from "react-icons/md";
import { getFileFromKey } from "../../helpers/apis/user";
import { useSelector } from "react-redux";

function MatchTab({ data, fetchTournament }) {
  const [showMatchData, setShowMatchData] = useState({
    show: false,
    match: {},
    round: {},
  });

  const auth = useSelector((state) => state.auth);
  const ref = useRef();

  const [teamProfiles, setTeamProfiles] = useState({});


  useEffect(() => {
    const fetchImages = async (key) => {
      try {
        const result = await getFileFromKey(key, auth);
        return result.data.success ? result.data.url : key;
      } catch (error) {
        console.error("Error fetching image:", error);
        return "https://www.gstatic.com/onebox/sports/logos/crest_48dp.png"; // Default fallback image
      }
    };

    const fetchTeamProfiles = async () => {
      const newProfiles = {};

      // Fetch images for all teams in all matches
      await Promise.all(
        data?.matches?.rounds?.flatMap((round) =>
          round?.matches?.map(async (match) => {
            if (match?.teamA?.profile) {
              if (!newProfiles[match.teamA.profile]) {
                // Fetch and set only if the image for teamA isn't already cached
                newProfiles[match.teamA.profile] = await fetchImages(
                  match.teamA.profile
                );
              }
            }

            if (match?.teamB?.profile) {
              if (!newProfiles[match.teamB.profile]) {
                // Fetch and set only if the image for teamB isn't already cached
                newProfiles[match.teamB.profile] = await fetchImages(
                  match.teamB.profile
                );
              }
            }
          })
        )
      );

      // Update the state with fetched profiles
      setTeamProfiles(newProfiles);
    };

    fetchTeamProfiles();
  }, [data, auth]);

  return (
    <div className="mb-10 mt-6">
      <div className="overflow-auto">
        {data?.matches?.rounds
          .sort((a, b) => a.round_no - b.round_no)
          .map((round) => (
            <div className="mb-4  sm:mx-12" key={round.round_no}>
              <h1 className="font-semibold text-slate-400/50 text-sm sm:text-base">
                {round?.name}
              </h1>
              {round?.matches
                ?.sort((a, b) => a.match_no - b.match_no)
                .map((match) => (
                  <div
                    className="bg-white border shadow-md rounded-lg p-4 sm:p-6 my-4 cursor-pointer"
                    onClick={() => {
                      if (match?.teamA?.club && match?.teamB?.club)
                        setShowMatchData((prvs) => ({
                          ...prvs,
                          show: true,
                          match,
                          round: {
                            round_no: round?.round_no,
                            round_name: round?.name,
                          },
                        }));
                    }}
                    key={match.match_no}
                    ref={
                      data?.matches?.c_match[0] ===
                        parseInt(round?.round_no, 10) + 1 &&
                        data?.matches?.c_match[1] ===
                        parseInt(match?.match_no, 10) + 1
                        ? ref
                        : null
                    }
                  >
                    <div className="flex flex-col sm:flex-row justify-between gap-4 sm:gap-6 px-2 sm:px-6 mb-2 sm:items-center">
                      <div className="flex flex-grow justify-between items-center gap-2">
                        <div className="flex gap-x-2 flex-col sm:flex-row sm:gap-x-4 items-center w-52">
                          <img
                            src={
                              teamProfiles[match.teamA?.profile] ||
                              "https://www.gstatic.com/onebox/sports/logos/crest_48dp.png"
                            }
                            className="w-12 h-12 sm:w-16 sm:h-16 rounded-full"
                            alt="Team A"
                          />
                          <h1 className="text-center sm:text-left text-xs sm:text-lg font-medium ">
                            {match?.teamA?.name ? (
                              match.teamA.name
                            ) : (
                              <span className="text-slate-500">Team A</span>
                            )}
                          </h1>
                        </div>
                        {/* Match Score Section */}
                        <div className="flex flex-col items-center flex-grow-1 flex-shrink-0 w-24 sm:w-52">
                          <h1 className="font-semibold text-xs sm:text-sm text-slate-400/50 text-center">
                            Match {parseInt(match?.match_no, 10) + 1}
                          </h1>

                          <div className="flex gap-x-1 sm:gap-x-2 justify-center items-center  mt-1 sm:mt-1">
                            <h1 className="text-xl sm:text-2xl font-medium">
                              {match?.teamA?.goals >= 0
                                ? match?.teamA?.goals
                                : "-"}
                            </h1>
                            <h1 className="text-slate-400 font-medium">-</h1>
                            <h1 className="text-xl sm:text-2xl font-medium">
                              {match?.teamB?.goals >= 0
                                ? match?.teamB?.goals
                                : "-"}
                            </h1>
                          </div>

                          {match?.complete && (
                            <div className="text-center text-sm sm:text-base font-medium text-green-500">
                              Full Time{match?.draw && " | Draw"}
                            </div>
                          )}

                          <div className="hidden sm:block w-52">
                            {match?.draw && match?.tieBreaker?.breakerType && (
                              <div className="text-center text-sm sm:text-base font-medium text-orange-500">
                                {match.tieBreaker.teamA.goals >
                                  match.tieBreaker.teamB.goals
                                  ? `${match.teamA.name} won by ${match.tieBreaker.breakerType} (${match.tieBreaker.teamA.goals} - ${match.tieBreaker.teamB.goals})`
                                  : `${match.teamA.name} won by ${match.tieBreaker.breakerType} (${match.tieBreaker.teamB.goals} - ${match.tieBreaker.teamA.goals})`}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="flex gap-x-2 flex-col-reverse sm:flex-row sm:gap-x-4 items-center w-52">
                          <h1 className="text-center sm:text-right text-xs sm:text-lg font-medium ">
                            {match?.teamB?.name ? (
                              match.teamB.name
                            ) : (
                              <span className="text-slate-500">Team B</span>
                            )}
                          </h1>
                          <img
                            src={
                              teamProfiles[match.teamB?.profile] ||
                              "https://www.gstatic.com/onebox/sports/logos/crest_48dp.png"
                            }
                            className="w-12 h-12 sm:w-16 sm:h-16 rounded-full"
                            alt="Team B"
                          />
                        </div>
                      </div>
                      <div className="block sm:hidden text-center">
                        {match?.draw && match?.tieBreaker?.breakerType && (
                          <div className="text-center text-sm sm:text-base font-medium text-orange-500">
                            {match.tieBreaker.teamA.goals >
                              match.tieBreaker.teamB.goals
                              ? `${match.teamA.name} won by ${match.tieBreaker.breakerType} (${match.tieBreaker.teamA.goals} - ${match.tieBreaker.teamB.goals})`
                              : `${match.teamA.name} won by ${match.tieBreaker.breakerType} (${match.tieBreaker.teamB.goals} - ${match.tieBreaker.teamA.goals})`}
                          </div>
                        )}
                      </div>
                      <hr className="transform rotate-90 my-auto w-12 hidden sm:block sm:w-16 border-gray-300" />
                      <div className="flex flex-col items-center text-xs sm:text-sm">
                        <div>{data.start_date}</div>
                        <div className="flex items-center gap-1">
                          <MdOutlineLocationOn />
                          <div className="whitespace-nowrap">{data.city}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </div>
      <MatchData
        openState={showMatchData?.show}
        data={showMatchData}
        isHost={data?.isHost}
        tournamentId={data._id}
        fetchTournament = {fetchTournament}
        close={() => {
          setShowMatchData((prvs) => ({
            ...prvs,
            match: {},
            round: {},
            show: false,
          }));
        }}
      />
    </div>
  );
}

MatchTab.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MatchTab;
